import request from '../utils/request'
import baseUrl from './baseUrl'

export const toolList = (data) => request({
  url: baseUrl + '/marketing/tool/selectForBack',
  method: 'POST',
  data
})

export const addTool = (data) => request({
  url: baseUrl + '/marketing/tool/add',
  method: 'POST',
  data
})

export const toolDetail = (data) => request({
  url: baseUrl + '/marketing/tool/selectForId',
  method: 'POST',
  data
})

export const delTool = (data) => request({
  url: baseUrl + '/marketing/tool/deleteById',
  method: 'POST',
  data
})

export const changeStatus = (data) => request({
  url: baseUrl + '/marketing/tool/modify',
  method: 'POST',
  data
})

export const toolLogList = (data) => request({
  url: baseUrl + '/marketing/log/selectForBack',
  method: 'POST',
  data
})

export const toolLogDetails = (data) => request({
  url: baseUrl + '/marketing/log/selectForId',
  method: 'POST',
  data
})